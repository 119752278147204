import Vue from "vue";

/**
 * @Author: firstFrost
 * @Date: 2023-07-23 19:13:18
 * @description: 获取某个组件渲染的dom根元素
 * @param {*} comp 组件
 * @param {*} props 属性
 * @return {HTMLElement} 元素
 */
export default function getComponentRootDom(comp, props){
    const vm = new Vue({
        render: (h) => h(comp, { props }),
    });
    vm.$mount();
    return vm.$el;
}