/** @format */

// 404组件
import NotFound from '@/views/NotFound.vue';

// 导入进度条css js
import 'nprogress/nprogress.css';
import { start, done, configure } from 'nprogress';

// configure({
//   trickleSpeed: 14, // 进度条移动速度
// })


// 所有组件加载进度条
function getPageComponent(pageCompResolve) {
  return async () => {
    // console.log('组件加载开始');
    start();
    const comp = await pageCompResolve();
    // console.log('组件加载结束');
    done();
    return comp;
  };
}

export default [
  {
    name: 'Home',
    path: '/',
    component: getPageComponent(() =>
      import(/* webpackChunkName: "about" */ '@/views/Home/Home.vue')
    ),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'About',
    path: '/about',
    component: getPageComponent(() => import(/* webpackChunkName: "about" */ '@/views/About.vue')),
    meta: {
      title: '关于我',
    },
  },
  {
    name: 'Blog',
    path: '/blog',
    component: getPageComponent(() =>
      import(/* webpackChunkName: "blog" */ '@/views/Blog/Blog.vue')
    ),
    meta: {
      title: '文章',
    },
  },
  {
    name: 'CategoryBlog',
    path: '/blog/cate/:categoryId',
    component: getPageComponent(() =>
      import(/* webpackChunkName: "blog" */ '@/views/Blog/Blog.vue')
    ),
    meta: {
      title: '文章',
    },
  },
  {
    name: 'BlogDetail',
    path: '/blog/:id',
    component: getPageComponent(() =>
      import(/* webpackChunkName: "blog" */ '@/views/Blog/Detail.vue')
    ),
    meta: {
      title: '文章详情',
    },
  },
  {
    name: 'Message',
    path: '/message',
    component: getPageComponent(() => import(/* webpackChunkName: "blog" */ '@/views/Message.vue')),
    meta: {
      title: '留言板',
    },
  },
  {
    name: 'Project',
    path: '/project',
    component: getPageComponent(() => import(/* webpackChunkName: "blog" */ '@/views/Project.vue')),
    meta: {
      title: '项目&效果',
    },
  },
  {
    name: 'NotFound',
    path: '*',
    component: NotFound,
  },
];
