import { render, staticRenderFns } from "./ToTop.vue?vue&type=template&id=755c324a&scoped=true&"
import script from "./ToTop.vue?vue&type=script&lang=js&"
export * from "./ToTop.vue?vue&type=script&lang=js&"
import style0 from "./ToTop.vue?vue&type=style&index=0&id=755c324a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755c324a",
  null
  
)

export default component.exports