import loadingUrl from '@/assets/loading.svg';
import  styles from './loading.module.less';

// 判断el中是否存在loading效果的img元素
function isExists(el){
    return el.querySelector('img[data-role=loading]');
}

function createLoadingImg(){
    const img = document.createElement('img')
    img.dataset.role = 'loading';
    img.src = loadingUrl;
    img.className = styles.loading;
    return img;
}


// 导出指令的配置对象
export default (el, binding) => {
    // 根据 binding.value 的值, 决定创建删除img元素
    const imgDom = isExists(el);
    if(binding.value){
        if(!imgDom){
            const img = createLoadingImg();
            el.appendChild(img);
        }
    }else{
        if(imgDom){
            imgDom.remove();
        }
    }
}

// export default {
//     bind(el, binding){
//             // 根据 binding.value 的值, 决定创建删除img元素
//             console.log('进入', binding);
//         if(binding.value){
//             if(!isExists(el)){
//                 const img = createLoadingImg();
//                 el.appendChild(img);
//                 console.log('添加img元素');
//             }
//         }else{
//             console.log('删除删除');
//         }
//     },
//     update(el, binding){
//             // 根据 binding.value 的值, 决定创建删除img元素
//         if(binding.value){
//             if(isExists(el)){
//                 const img = createLoadingImg();
//                 el.appendChild(img);
//                 console.log('添加img元素');
//             }
//         }else{
//             console.log('删除删除');
//         }
//     }
// }
