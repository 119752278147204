/** @format */

// 图片懒加载  自定义指令
import eventBus from '@/eventBus.js';
import { debounce } from '@/utils';
import defaultGif from '@/assets/default.gif';

let imgs = [];

// 处理单张图片
function setImage(img) {
    img.dom.src = defaultGif; // 加载中图片
    // 判断图片是否在视口范围内
    const clientHeight = document.documentElement.clientHeight;
    const rect = img.dom.getBoundingClientRect();
    const height = rect.height || 150;
    if(rect.top >= -height && rect.top <= clientHeight){
        // 在视口范围内
        // const tempImg = new Image();
        // tempImg.onload = function(){
        //     img.dom.src = tempImg.src;
        //     // 图片加载成功
        //     img.dom.onload = null;
        // }
        // tempImg.src = img.src; // 图片加载
        img.dom.src = img.src;
        // 把处理过的图片删除
        imgs = imgs.filter((item) => item !== img);
        return;
    }
}

// 调用该函数,请求可见视口的图片
function setImages() {
	for (const img of imgs) {
		setImage(img);
	}
}

function handleScroll(dom) {
	setImages(dom);
}

eventBus.$on('mainScroll', debounce(handleScroll, 50));

export default {
	inserted(el, bindings) {
		let img = {
			dom: el,
			src: bindings.value,
		};

		imgs.push(img); // 放入数组

		setImage(img); // 立即处理
	},
	unbind(el) {
		imgs = imgs.filter((item) => item.dom !== el);
	},
};
