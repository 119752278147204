// 数据仓库模块   VueX

// import Vuex from 'vuex';
import { Store, install } from 'vuex'; // 具名导出, 节省一点打包体积
import Vue from 'vue';
import banner from './banner.js';
import setting from './setting.js';
import about from './about.js';
import project from './project.js';
// Vue.use(Vuex); // 应用VueX插件
if(!window.Vue){ // 判断是否使用CDN
    install(Vue);
}


export default new Store({
  modules: {
    banner,
    setting,
    about,
    project,
  },
  strict: true, // 开启严格模式
});

// export default new Vuex.Store({
//     modules:{
//         banner,
//         setting,
//         about,
//         project,
//     },
//     strict:true, // 开启严格模式
// })