<template>
  <ul class="contact-container">
    <li>
        <a target="_blank" :href="data.github">
            <div class="icon">
                <Icon type="github"></Icon>
            </div>
            <span>{{ data.githubName }}</span>
        </a>
        <!-- <div class="pop">
            <img src="https://img2.baidu.com/it/u=1519965074,817006438&fm=253&fmt=auto&app=138&f=PNG?w=200&h=200" alt="">
        </div> -->
    </li>
    <li>
        <a target="_blank" :href="`mailto:${data.mail}`">
            <div class="icon">
                <Icon type="mail"></Icon>
            </div>
            <span>{{ data.mail }}</span>
        </a>
        <!-- <div class="pop">
            <img src="https://img2.baidu.com/it/u=1519965074,817006438&fm=253&fmt=auto&app=138&f=PNG?w=200&h=200" alt="">
        </div> -->
    </li>
    <li>
        <a target="_blank" :href="`tencent://message/?Menu=yes&uin=${data.qq}&Service=300&sigT=45a1e5847943b64c6ff3990f8a9e644d2b31356cb0b4ac6b24663a3c8dd0f8aa12a595b1714f9d45`">
            <div class="icon">
                <Icon type="qq"></Icon>
            </div>
            <span>{{ data.qq }}</span>
        </a>
        <div class="pop">
            <img :src="qqQrCode" alt="">
        </div>
    </li>
    <li>
        <a target="_blank" href="">
            <div class="icon">
                <Icon type="weixin"></Icon>
            </div>
            <span>{{ data.weixin }}</span>
        </a>
        <div class="pop">
            <img :src="weixinQrCode" alt="" @click="showInfo">
        </div>
    </li>
  </ul>
</template>

<script>
import Icon from "@/components/Icon";
import { mapState } from "vuex";
import { server_URL } from "@/baseUrl"; // 处理图片路径问题
export default {
    name:'Contact',
    components:{
        Icon,
    },
    computed:{
        ...mapState('setting', ['data']),
        qqQrCode(){
            return this.data.qqQrCode.replace(server_URL, '');
        },
        weixinQrCode(){
            return this.data.weixinQrCode.replace(server_URL, '');
        }
    },
    methods:{
        showInfo(){
            console.log(this.data);
        }
    }
}
</script>

<style scoped lang="less">
@import "@/styles/var.less";
    .contact-container{
        width: 100%;
        list-style:none;
        margin: 0;
        padding: 20px;
        color: @gray;
        @itemHeight:30px;
        box-sizing: border-box;
        li{
            height: 30px;
            line-height: 30px;
            margin: 14px 0;
            position: relative;
            &:hover{
                .pop{
                    transform: scaleY(1);
                }
            }
        }
        a{
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        .icon{
            font-size: 26px;
            width: 36px;
        }
        .pop{
            position:absolute;
            left: 0;
            bottom:@itemHeight + 5px;
            padding: 10px 15px;
            background-color: #fff;
            border-radius: 5px;
            transform: scaleY(0);
            transform-origin: center bottom;
            transition: 200ms;
            img{
                width: 150px;
                height: 150px;
            }
            &::after{
                content:"";
                position: absolute;
                left: 50%;
                transform: translate(-50%,50%) rotate(45deg);
                background-color: #fff;
                width: 8px;
                height: 8px;
                bottom: 0;
            }
        }
    }
</style>