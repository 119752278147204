<template>
  <div class="not-found-container">
    <img src="@/assets/404.jpg" alt="">
  </div>
</template>
<script>
export default {
  name: 'NotFound',
}
</script>
<style lang="less" scoped>
.not-found-container{
    background-color: #90cbc0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
