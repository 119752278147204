<template>
  <div @click="handleClick" v-show="show" class="to-top-container">
    Top
  </div>
</template>
<script>
export default {
  name: 'ToTop',
  data(){
    return{
        show: false,
    }
  },
  created(){
    this.$bus.$on('mainScroll', this.handleScroll);
  },
  methods:{
    handleScroll(dom){
        if(!dom){
            this.show = false;
            return; // 没有dom不执行
        }
        this.show = dom.scrollTop >= 300;
    },
    handleClick(){
        // 回到顶部
        this.$bus.$emit('setMainScroll', 0);
    }
  },
  beforeDestroy(){
    this.$bus.$off('mainScroll', this.handleScroll);
  }
}
</script>
<style lang="less" scoped>
    @import '@/styles/var.less';
    .to-top-container{
        background-color: @primary;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: fixed;
        right: 3%;
        bottom: 3%;
        z-index: 1000;
        /* 阴影 */;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 500;
    }
</style>
