// import './mock/index.js'


import Vue from 'vue'
import App from './App.vue'
import './styles/global.less'
import router from './router/index.js'
import { showMessage } from './utils'

Vue.config.productionTip = false
import '@/eventBus.js';
Vue.prototype.$showMessage = showMessage;
import '@/api/banner.js';

//注册全局指令
import vLoading from '@/directives/loading.js';
import vLazy from '@/directives/lazy.js';
Vue.directive('loading', vLoading);
Vue.directive('lazy', vLazy);
// vuex
import store from './store/index.js';
// 全局网站设置 一开始触发一下
store.dispatch('setting/fetchSetting');

new Vue({
    router,
    store,
    render: h => h(App),
    // beforeCreate() {
    //     Vue.prototype.$bus = this // 安装全局事件总线,$bus是当前的vm
    // }
}).$mount('#app')

/**
 * 全局事件总线
 * 
 * 事件名: mainScroll
 * 含义: 主区域滚动条位置变化后触发
 * 参数: 需要监听滚动条的dom元素
 * --- 滚动的dom元素,如果是undefined,则表示dom元素已经不存在了
 * 
 * 
 * 
 * 事件名: setMainScroll
 * 含义: 设置主区域滚动条位置时触发
 * 参数: 滚动高度
 * 
 * 
 * 
 */