<template>
  <img class="loading-container" src="@/assets/loading.svg" alt="">
</template>

<script>
export default {
    name:'Loading',
}
</script>

<style scoped lang="less">
@import '@/styles/mixin.less';
    .loading-container{
        .self-center();
    }
</style>