import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes.js'
import { titleController } from '@/utils/index.js'
// 使用路由
if(!window.VueRouter){
  Vue.use(VueRouter); // 判断是否使用CDN
}

const router = new VueRouter({
    // 配置
    routes,
    mode:'history',
})

// 确认之后
router.afterEach((to, from) => {
    if(to.meta.title){
        titleController.setRouteTitle(to.meta.title);
    }
})

export default router;