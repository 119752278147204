<template>
  <div class="avatar-container">
    <img 
        class="avatar-container"
        :src="handleUrl" 
        :style="{
            width: size + 'px',
            height: size + 'px'
        }" 
    />
  </div>
</template>

<script>
import { server_URL } from '@/baseUrl.js';
export default {
    name:'Avatar',
    props:{
        url:{
            type:String, // 属性必须是字符串
            // required:true, // 属性必传
            default:'https://qiheizhiya.oss-cn-shenzhen.aliyuncs.com/image/avatar4.jpg',
        },
        size:{
            type:Number,
            default:50,
        }
    },
    computed:{
        handleUrl(){
            return this.url.replace(server_URL, '');
        }
    }
}
</script>

<style scoped>
    .avatar-container{
        object-fit: cover;
        border-radius: 50%;
        display: block;
    }
</style>