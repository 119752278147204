<template>
  <div id="app">
    <Layout>
      <template #left>
        <div class="left">
          <SiteAside></SiteAside>
        </div>
      </template>
      <template>
        <div class="main">
          <RouterView></RouterView>
        </div>
      </template>
      <!-- <template #right>
        <div class="right">
        </div>
      </template> -->
    </Layout>
    <ToTop></ToTop>
  </div>
</template>

<script>
import Layout from './components/Layout.vue';
import Loading from './components/Loading.vue';
import SiteAside from './components/SiteAside/SiteAside.vue';
import ToTop from './components/ToTop.vue';
export default {
  name: "App",
  components: {
    Layout,
    SiteAside,
    Loading,
    ToTop,
  },
  data() {
    return {
    }
  },
  methods: {
    handlePageChange(newPage) {
      this.current = newPage;
      // console.log('加载当前页数据');
    },
    handleLoaded(){
      // console.log("load事件触发,图片加载完成");
    },
  },
};
</script>

<style scoped>
  #app{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .left{
    width: 250px;
    height: 100%;
  }
  .main{
    height: 100%;
    /* overflow: auto; */
  }
  /* .left{
    width: 250px;
    height: 100%;
  }
  .main{
    width: 100%;
    height: 100%;
  }
  .right{
    width: 250px;
    height: 100%;
  } */
</style>
