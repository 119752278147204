/** @format */

import { getSetting } from '@/api/setting';
import { titleController } from '@/utils';
import { server_URL } from '@/baseUrl';

export default {
  namespaced: true,
  state: {
    loading: false,
    data: null,
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setData(state, payload) {
      state.data = payload;
    },
  },
  actions:{
    async fetchSetting(ctx){
        ctx.commit('setLoading', true);
        const resp = await getSetting();
        resp.avatar = server_URL + resp.avatar;
        resp.qqQrCode = server_URL + resp.qqQrCode;
        resp.weixinQrCode = server_URL + resp.weixinQrCode;
        ctx.commit('setData', resp);
        ctx.commit('setLoading', false);
        // 设置小图标
        if(resp.favicon){
          let link = document.querySelector('link[rel="shortcut icon"]');
          if(link){
            return;
          }
          link = document.createElement('link');
          link.rel = 'shortcut icon';
          link.type = 'image/x-icon';
          link.href = resp.favicon;
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        // 设置网站标题
        titleController.setSiteTitle(resp.siteTitle);
    }
  }
};
