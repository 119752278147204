<template>
  <!-- <div class="icon-container">
    
  </div> -->
  <i class="iconfont icon-container" :class="fontClass"></i>
</template>

<script>
const classMap = {
  home: "iconzhuye",
  success: "iconzhengque",
  error: "iconcuowu",
  close: "iconguanbi",
  warn: "iconjinggao",
  info: "iconxinxi",
  blog: "iconblog",
  code: "iconcode",
  about: "iconset_about_hov",
  weixin: "icon-sign_wechat",
  mail: "iconemail",
  github: "icongithub",
  qq: "iconsign_qq",
  arrowUp: "iconiconfonticonfonti2copy",
  arrowDown: "iconiconfonticonfonti2",
  empty: "iconempty",
  chat: "iconliuyan",
};
export default {
    name:'Icon',
    props:{
        type:{
            type:String,
            required: true,

        }
    },
    computed:{
        // 图标类样式
        fontClass(){
            return classMap[this.type]
        }
    }
}
</script>

<style scoped>
    /* 微信图标 */
    @import '//at.alicdn.com/t/c/font_4172549_9uqoxtjh76a.css';
    /* 其余图标 */
    @import '//at.alicdn.com/t/font_2164449_nalfgtq7il.css';
    .icon-container{
        font-size: inherit;
        color:inherit;
    }
</style>