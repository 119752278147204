import getComponentRootDom from "./getComponentRootDom";
import Icon from '@/components/Icon.vue';
import styles from './showMessage.module.less';


/**
 * @Author: firstFrost
 * @Date: 2023-07-23 20:23:36
 * @description: 
 * @param {Object} options
 * @param {String} options.content 消息内容
 * @param {String} options.type 消息类型
 * @param {Number} options.duration 消失的时间,毫秒
 * @param {HTMLElement} options.container 容器,消息会显示到该容器的正中间,如果不传,则显示到页面正中间
 * @param {Function} options.callback 回调函数,元素消失的时候运行
 * @return {*}
 */
export default function showMessage(options = {}){
    const content = options.content || '';
    const type = options.type || 'info';
    const duration = options.duration || 1000;
    const container = options.container || document.body;


    // 创建消息元素
    const div = document.createElement('div');
    const iconDom = getComponentRootDom(Icon, {
        type,
    })
    div.innerHTML = `
        <span class="${styles.icon} ">${iconDom.outerHTML}</span>
        <div>${content}</div>
    `;
    
    // 设置样式
    div.className = `${styles.message} ${styles["message-" + type]}`;

    // 将div加到容器中
    // 容器的position是否被改动过, 如果是body,就不添加position:relative
    if(options.container){
        if(window.getComputedStyle(container, null).position === 'static'){
            container.style.position = 'relative';
        }
    }



    container.appendChild(div);
    // 浏览器强行渲染
    div.clientHeight; // 导致 reflow
    
    // 回归到正常位置
    div.style.opacity = 1;
    div.style.transform = `translate(-50%, -50%)`;


    // 等一段时间消失
    setTimeout(() =>{
        div.style.opacity = 0;
        div.style.transform = `translate(-50%, -50%) translateY(-15px)`;
        div.addEventListener('transitionend', function(){
            div.remove();
            // 运行回调函数
            options.callback && options.callback();
        }, {
            once: true,
        })
    }, duration);
}