<!-- @format -->

<template>
  <div class="site-aside-container">
    <template v-if="data">
      <Avatar :url="data.avatar" :size="150"></Avatar>
      <h1 class="title">{{ data.siteTitle }}</h1>
      <Menu></Menu>
      <Contact></Contact>
      <p class="footer">{{ data.record }}</p>
    </template>
  </div>
</template>

<script>
import Avatar from '../Avatar.vue';
import Contact from './Contact.vue';
import Menu from './Menu.vue';
import { mapState } from 'vuex';
export default {
  name: 'SiteAside',
  components: {
    Avatar,
    Contact,
    Menu,
  },
  computed: mapState('setting', ['data']),
};
</script>

<style scoped lang="less">
@import '@/styles/var.less';
.site-aside-container {
  width: 100%;
  height: 100%;
  background-color: @dark;
  padding: 20px 0 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px; /* 设置滚动条宽度 */
  }
  &::-webkit-scrollbar-thumb {
    background-color: @words; /* 设置滚动条的颜色 */
    border-radius: 3px; /* 设置滚动条的圆角 */
  }
  &::-webkit-scrollbar-track {
    background-color: darken(@words, 4%); /* 设置滚动条轨道的颜色 */
  }
}

.avatar-container {
  display: flex;
  justify-content: center;
  align-content: center;
}
.footer {
  text-align: center;
  font-size: 12px;
}
.title {
  font-size: 1em;
  color: #fff;
  text-align: center;
}
</style>
