/** @format */

export default function (fn, duration) {
	let timer = null;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			fn(...args);
		}, duration);
	};
}
